@import url("./satellite-min.css");
/* @import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type="checkbox"] {
    @apply accent-brand-nebula;
  }
  html {
    @apply bg-brand-uniwhite;
  }

  :root {
    --sleek-active-indicator: #fe5c46;
    --macmerise-brand-primary: #604bcc;
    --mac-check: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%23604BCC%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpolyline%20points%3D%2220%206%209%2017%204%2012%22%3E%3C%2Fpolyline%3E%3C%2Fsvg%3E");

    /* --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 240, 84%, 49%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem; */
    --background: 0 0% 100%; /* Keeping it white */
    --foreground: 0 0% 6.3%; /* Brand charcoal for all text */
    --card: 0 0% 100%; /* Keeping it white */
    --card-foreground: 0 0% 6.3%; /* Darker version of the primary color */
    --popover: 0 0% 100%; /* Keeping it white */
    --popover-foreground: 0 0% 6.3%; /* Darker version of the primary color */
    --primary: 251 50.7% 55.6%; /* Adjusted HSL for #604bcc */
    --primary-foreground: 240 10% 95%; /* Lightened version for contrast */
    --secondary: 251 50.7% 90%; /* Lightened primary color */
    --secondary-foreground: 0 0% 6.3%; /* Darker primary color */
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 251 50.7% 90%; /* Similar to secondary for uniformity */
    --accent-foreground: 0 0% 6.3%; /* Darker primary color */
    --destructive: 0 84.2% 60.2%; /* Keeping this as a red tone for destructive actions */
    --destructive-foreground: 240 10% 95%; /* Lightened for contrast */
    --border: 245.7 50% 85%; /* Light border color */
    --input: 245.7 50% 85%; /* Input border similar to general border */
    --ring: 251 50.7% 55.6%; /* Same as primary */
    --radius: 0.5rem; /* Keeping the border radius consistent */
    --chart-1: 12 76% 61%; /* Keeping existing chart colors */
    --chart-2: 173 58% 39%; /* Keeping existing chart colors */
    --chart-3: 197 37% 24%; /* Keeping existing chart colors */
    --chart-4: 43 74% 66%; /* Keeping existing chart colors */
    --chart-5: 27 87% 67%; /* Keeping existing chart colors */
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-brand-uniwhite text-foreground w-full min-w-[100vw] !overflow-x-hidden;
  }
}

/* contained-slider first and last span should not have a border radius */
.contained-slider > span:first-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.contained-slider > span:last-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.contained-slider > span:not(:first-child):not(:last-child) {
  border-radius: 0;
}

@layer utilities {
  .accordion-text > * {
    @apply text-brand-charcoal text-[14px] leading-[1.5] my-[15px] font-[400];
  }
  .contained-slider > span {
    @apply transition-all duration-300 animate-in slide-in-from-left-0;
  }
}

:root {
  font-family: "Graphik", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: rgba(0, 0, 0, 1);

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

/* PRODUCT DETAILS CAROUSEL STYLING CUSTOMIZATION START */
.productCarousel.carousel-root {
  display: flex;
  flex-direction: row-reverse;
  gap: 15px;
}
.productCarousel.carousel-root .carousel {
  width: auto;
}
.productCarousel.carousel-root .carousel.carousel-slider {
  width: 100%;
}
.productCarousel.carousel-root .carousel.carousel-slider .slider-wrapper {
  border-radius: 10px;
}
.productCarousel.carousel-root .carousel .thumbs-wrapper {
  margin: 0;
}
.productCarousel.carousel-root .carousel .thumb {
  height: 60px;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}
.productCarousel.carousel-root .carousel .thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.productCarousel.carousel-root .carousel .thumb:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0;
}
.productCarousel.carousel-root .carousel .thumb.selected,
.productCarousel.carousel-root .carousel .thumb:hover {
  border: 0;
}
.productCarousel.carousel-root .carousel .thumb:hover:after,
.productCarousel.carousel-root .carousel .thumb.selected:after {
  opacity: 0.2;
}
.productCarousel .control-arrow {
  display: none;
}
@media screen and (max-width: 767px) {
  .footer-content h2 {
    font-size: 14px !important;
  }
  .footer-content h3 {
    font-size: 14px !important;
  }
  .footer-content p {
    font-size: 12px !important;
  }
  /* .footer-content div{margin-top: 40px !important;} */
  .footer-content .que {
    font-size: 14px !important;
    margin-top: 20px !important;
  }
  .footer-content .ans {
    font-size: 12px !important;
  }
  .footer-content .faq {
    margin-top: 60px !important;
  }

  .witharrow .swiper-button-prev,
  .witharrow .swiper-button-next {
    top: 32% !important;
    width: 25px !important;
    height: 25px !important;
  }
  .productCarousel.carousel-root {
    flex-direction: column;
  }
  .productCarousel.carousel-root .carousel .thumb {
    border: 0;
    padding: 0;
  }
  .Toastify__toast-container {
    width: 50% !important;
    font-size: 12px;
    top: 15px !important;
    margin-left: auto !important;
  }
}
@media screen and (min-width: 768px) {
  .productCarousel.carousel-root .carousel .thumbs {
    transform: none !important;
    flex-direction: column;
    display: flex;
    gap: 10px;
  }
  .productCarousel.carousel-root .carousel .thumb {
    border: 0;
    padding: 0;
    margin: 0;
  }
}
/* PRODUCT DETAILS CAROUSEL STYLING CUSTOMIZATION END */

.markdown ul {
  margin: 0;
  padding: 0;
  list-style-type: disc;
  margin: 20px 0;
}
.markdown ul li {
  margin: 10px 0;
}

/* Macmerise CSS Starts */

.margin-0-6 {
  margin: 0 6px;
}
.margin-0-12 {
  margin: 0 12px;
}

.social-links {
  position: absolute;
  margin-top: 70px;
}
.social-links ul {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.social-links ul li {
  list-style: none;
}
.social-links ul li a {
  width: 45px;
  height: 45px;
  background-color: #fff;
  text-align: -webkit-center;
  font-size: 20px;
  margin: 0 15px;
  display: block;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  border: 3px solid #fff;
  z-index: 1;
}
.social-links ul li a .icon {
  position: relative;
  color: #262626;
  transition: 0.5s;
  z-index: 3;
  margin-top: 9px;
}
.social-links ul li a:hover .icon {
  color: #fff;
  transform: rotateY(360deg);
}
.social-links ul li a:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f00;
  transition: 0.5s;
  z-index: 2;
}
.social-links ul li a:hover:before {
  top: 0;
}
.social-links ul li:nth-child(1) a:before {
  background: linear-gradient(
    45deg,
    #405de6,
    #5851db,
    #833ab4,
    #e1306c,
    #fd1d1d
  );
}
.social-links ul li:nth-child(2) a:before {
  background: #3b5998;
}
.social-links ul li:nth-child(3) a:before {
  background: #1da1f2;
}
.social-links ul li:nth-child(4) a:before {
  background: #ff0000;
}

/* Filter Slider Starts */
.main {
  background-color: transparent;
  grid-area: main;
  padding: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  margin: 0 auto;
}
.items {
  position: relative;
  width: 100%;
  white-space: nowrap;
  transition: all 0.2s;
  will-change: transform;
  user-select: none;
  cursor: pointer;
  display: flex;
}
.items::-webkit-scrollbar {
  width: 0 !important;
}
.items {
  overflow: -moz-scrollbars-none;
}
.items {
  -ms-overflow-style: none;
}
.cat-inner {
  position: relative;
  padding: 15px 0;
  cursor: -webkit-grabbing;
  border: 1px solid transparent;
  color: #000;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  min-width: 170px !important;
  margin: 0 0 !important;
  font-size: 13px;
}
.cat-inner img {
  width: 65px;
  margin: 0 auto;
}
.cat-opacity {
  background-color: transparent;
  opacity: 0.6;
}
#spanCount {
  font-family: JustSans !important;
  position: absolute;
  top: 5px;
  right: 8px;
  color: #000;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 0.5px;
}
#dynamicFilter {
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
}
.type-inner {
  position: relative;
  padding: 0;
  cursor: -webkit-grabbing;
  border: 1px solid transparent;
  border-radius: 25px;
  color: #000;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  min-width: fit-content !important;
  margin: 0 0 !important;
  font-size: 13px;
}
/* Filter Slider Ends */

/* Design Filter Starts */
.drpFilter {
  outline: none;
  margin-bottom: 10px;
  padding: 0 10px;
  cursor: pointer;
  border-left: 1px solid #ccc;
  text-transform: capitalize;
  font-family: "JustSans";
  font-size: 15px;
  letter-spacing: 0.8px;
  font-weight: 500;
  width: 100%;
  display: block;
  position: relative;
  margin-right: 15px;
  max-width: 345px !important;
}
/* Design Filter Ends */

.blinkText {
  transform: translate(-50%, -50%);
}
.blinkText-In {
  animation: animate 1.5s linear infinite;
}

.btnCart-bg {
  background-image: linear-gradient(169deg, #b50505 26%, #930000 76%);
}
.btnCart-bg:hover {
  background-image: none;
}

.logistic-info {
  max-width: 556px;
}
.need-help-detail {
  border: 1px solid #000;
  border-radius: 8px;
  background-color: #f2f2f2;
  padding: 8px 10px;
  font-weight: 600;
  color: #000;
  margin-top: 0 !important;
  display: inline-block;
}

.truncPara-3 {
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-break: break-word;
}

.product-description-new p {
  padding: 0 20px 20px 22px !important;
  font-family: "Graphik";
  font-size: 18px !important;
  /* text-wrap: balance; */
  /* text-align: initial !important; */
  line-height: 1.8;
  color: #000;
}
.product-description-new ul li {
  padding: 0 20px 20px 28px !important;
  font-weight: 600;
  font-size: 16px !important;
  letter-spacing: 1px;
  line-height: normal;
  color: #000;
}
.product-description-new ul {
  margin-top: 28px;
}
.prod-flex-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.prod-flex-container-desc {
  display: flex;
  padding-top: 25px;
  background-color: #f1f2f3;
  font-size: 14px;
}
.prod-flex-child {
  flex: 1;
  vertical-align: middle;
}
.prod-flex-child:first-child {
  margin-right: 20px;
}
.prod-flex-child .one {
  order: 1;
}
.prod-flex-child .two {
  order: 2;
}
.prod-flex-child-desc {
  flex: 1;
  vertical-align: middle;
}
.prod-flex-child-desc:first-child {
  margin-right: 20px;
}
.prod-flex-child-desc:first-child:after {
  content: "";
  background-color: #adadad;
  position: absolute;
  width: 1px;
  height: 96%;
  top: 10px;
  left: 33.33%;
  display: block;
}
.prod-flex-child-desc-second:after {
  content: "";
  background-color: #adadad;
  position: absolute;
  width: 0.2px;
  height: 96%;
  top: 10px;
  left: 66.66%;
  display: block;
}
.product-description-new img {
  display: inline;
}
.product-description-new h3 {
  padding: 20px 20px 0px 20px;
  text-transform: uppercase;
  line-height: normal;
  font-size: 18px;
  font-weight: 500;
  color: #000;
  font-family: Graphik;
  text-align: left;
  margin-bottom: 10px;
}
.order-2 {
  order: 2;
}

@keyframes animate {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
@keyframes fadeInUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.slide-right {
  animation: 0.5s slide-right;
}
@keyframes slide-right {
  from {
    margin-left: -100%;
  }
  to {
    margin-left: 0%;
  }
}

.slide-left {
  animation: 0.5s slide-left;
}
@keyframes slide-left {
  from {
    margin-right: -100%;
  }
  to {
    margin-right: 0%;
  }
}

.slide-top {
  animation: 0.5s slide-top;
}
@keyframes slide-top {
  from {
    margin-bottom: -100%;
  }
  to {
    margin-bottom: 0%;
  }
}

.slide-left-device {
  animation: 0.5s slide-left-device;
}
@keyframes slide-left-device {
  from {
    margin-right: -30%;
  }
  to {
    margin-right: 10%;
  }
}

.animate-top {
  animation: animatetop 0.4s;
}
@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
.animate-left {
  animation: animateleft 0.4s;
}
@keyframes animateleft {
  from {
    left: -300px;
    opacity: 0;
  }
  to {
    left: 0;
    opacity: 1;
  }
}
.animate-right {
  animation: animateright 0.4s;
}
@keyframes animateright {
  from {
    right: -300px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}
.animate-bottom {
  animation: animatebottom 0.4s;
}
@keyframes animatebottom {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@media screen and (min-width: 768px) and (max-width: 887px) {
  .mx-13 {
    margin: 0 13px !important;
  }
}

@media screen and (max-width: 768px) {
  .react-multiple-carousel__arrow--left {
    display: none !important;
  }
  .react-multiple-carousel__arrow--right {
    display: none !important;
  }

  .social-links {
    position: relative;
    margin-top: 35px;
  }

  /* Filter Slider Starts */
  .main {
    width: 100%;
    overflow-x: scroll;
    margin: 15px auto 0 auto;
  }
  .items {
    white-space: inherit;
    overflow-x: scroll;
  }
  /* Filter Slider Ends */

  .product-description-new h3 {
    font-size: 14px !important;
    padding-left: 0;
  }
  .product-description-new p {
    font-size: 12px !important;
    padding-left: 0 !important;
  }
  .prod-flex-child:first-child {
    margin-right: 0 !important;
  }
  .prod-flex-container {
    padding-left: 0px !important;
    display: block;
  }
}

/* Macmerise CSS Ends */

/* Pagination*/
.rc-pagination {
  flex-wrap: wrap;
}
.rc-pagination-item,
.rc-pagination-prev,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
  margin-right: 8px;
}

.rc-pagination-total-text {
  margin-right: 12px;
  cursor: initial;
}

.rc-pagination-jump-next,
.rc-pagination-jump-prev,
.rc-pagination-next,
.rc-pagination-prev {
  display: inline-block;
  min-width: 28px;
  height: 28px;
  color: rgba(0, 0, 0, 0.85);
  font-family: Arial;
  line-height: 28px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
.rc-pagination-jump-next button,
.rc-pagination-jump-prev button {
  background: transparent;
  border: none;
  cursor: pointer;
  color: #666;
}
.rc-pagination-jump-next button:after,
.rc-pagination-jump-prev button:after {
  display: block;
  content: "...";
}
.rc-pagination-item,
.rc-pagination-prev,
.rc-pagination-next,
.rc-pagination-total-text {
  min-width: initial;
  height: auto;
  line-height: initial;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.rc-pagination-item a,
.rc-pagination-item button,
.rc-pagination-prev a,
.rc-pagination-prev button,
.rc-pagination-next a,
.rc-pagination-next button,
.rc-pagination-total-text a,
.rc-pagination-total-text button {
  padding: 6px 8px;
  height: auto;
  min-width: 32px;
  min-height: 32px;
  border-radius: 8px;
  border: 1px solid transparent;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 600;
  color: #000 !important;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
}
.rc-pagination-item.rc-pagination-item-active a,
.rc-pagination-item.rc-pagination-item-active a:hover,
.rc-pagination-prev.rc-pagination-item-active a,
.rc-pagination-prev.rc-pagination-item-active a:hover,
.rc-pagination-next.rc-pagination-item-active a,
.rc-pagination-next.rc-pagination-item-active a:hover,
.rc-pagination-total-text.rc-pagination-item-active a,
.rc-pagination-total-text.rc-pagination-item-active a:hover {
  background-color: #40df75;
  border-color: #40df75;
  color: #101010 !important;
  border-radius: 3px;
}
.rc-pagination-item a:hover,
.rc-pagination-item button:hover,
.rc-pagination-prev a:hover,
.rc-pagination-prev button:hover,
.rc-pagination-next a:hover,
.rc-pagination-next button:hover,
.rc-pagination-total-text a:hover,
.rc-pagination-total-text button:hover {
  background-color: #eceff5;
  border-color: #eceff5;
}
.table-filter-info ul li a {
  color: #000 !important;
  font-weight: 600;
  font-size: 15px;
  border-radius: 0;
}

.truncate-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.itemsCat {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  white-space: nowrap;
  transition: all 0.2s;
  will-change: transform;
  user-select: none;
  cursor: pointer;
  display: flex;
}

.rc-slider {
  width: 90% !important;
  margin-left: 8px;
}
.rc-slider-track {
  background-color: #604bcc !important;
}
.rc-slider-handle {
  border: 2px solid #604bcc !important;
  cursor: grabbing !important;
  background-color: #604bcc !important;
}
.rc-slider-rail {
  background-color: #dbdbdb !important;
}

.hide-scrollbar {
  scrollbar-width: thin; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.hide-scrollbar::-webkit-scrollbar {
  width: 0; /* For Chrome, Safari, and Opera */
}

.ml-0 {
  margin-left: 0 !important;
}
.ml-25 {
  margin-left: 25px !important;
}
.ml-30 {
  margin-left: 30px !important;
}
.ml-33 {
  margin-left: 33px !important;
}

.slick-prev:before,
.slick-next:before {
  color: #604bcc;
}

.cat-icon {
  line-height: initial;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  /* word-break: break-word; */
  text-align: center;
}
.search-container {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.search-container.open {
  max-height: 15rem; /* Adjust the height as needed */
  max-width: 768px;
  margin: 0 auto;
}

.search-container.closed {
  max-height: 0;
  max-width: 768px;
  margin: 0 auto;
}

.device-cat .react-multi-carousel-item {
  width: max-content !important;
  margin-right: 10px !important;
}
.device-cat .react-multiple-carousel__arrow--left {
  left: 0 !important;
}
.device-cat .react-multiple-carousel__arrow--right {
  right: 0 !important;
}

.article_content {
  margin: 25px 0;
}
.article_content p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
  font-family: "JustSans";
}
.article_content p strong {
  font-family: "Avenir-Black-03";
}
.article_content img {
  padding: 15px;
}

.search-box.open {
  position: absolute;
  top: 40px;
  z-index: 99;
  background: #000;
  width: 100%;
}

.search-box.closed {
  max-height: 0;
  max-width: 768px;
  margin: 0 auto;
  display: none;
}

.search-body-overlay {
  position: fixed;
  top: 115px;
  left: 0;
  width: 100%;
  height: -webkit-fill-available;
  background: rgba(36, 31, 31, 0.7) !important;
  z-index: 1;
}

.padding-0 {
  padding: 0 !important;
}

.home-banner {
  position: initial !important;
}
.home-banner:has(.ai) .slick-dots {
  position: initial;
  margin-bottom: 0px;
}
.home-banner:not(:has(.ai)) .slick-dots {
  height: 0;
  position: initial;
  margin-top: 20px;
}

/* .home-banner:not(:has(.ai)) .slick-dots on mobile margin top 16px */
@media screen and (max-width: 768px) {
  .home-banner:not(:has(.ai)) .slick-dots {
    margin-top: 0px;
    margin-bottom: 5rem;
  }
}

.home-banner .slick-dots li {
  margin-right: 5px;
  width: auto;
  height: auto;
}
.home-banner .slick-dots li button {
  background: #dbdbdb;
  height: 4px;
  width: 109px;
  padding: 0;
}
.home-banner .slick-dots .slick-active button {
  background: var(--sleek-active-indicator, #1e1e1e);
}
.home-banner .slick-dots li button:before {
  display: none;
}

.divTrend .slick-dots li {
  margin: 0;
  width: auto;
  height: auto;
}
.divTrend .slick-dots li button {
  background: #dbdbdb;
  height: 2px;
  width: 40px;
  padding: 0;
}
.divTrend .slick-dots .slick-active button {
  background: #1e1e1e;
}
.divTrend .slick-dots li button:before {
  display: none;
}
.divTrend .slick-slide {
  width: 25% !important;
}
.divTrend .slick-slide > div {
  margin: 0 5px;
}
.divTrend .slick-list {
  margin: 0 -10px;
}

.divNewIn .slick-dots li {
  margin: 0;
  width: auto;
  height: auto;
}
.divNewIn .slick-dots li button {
  background: #dbdbdb;
  height: 2px;
  width: 40px;
  padding: 0;
}
.divNewIn .slick-dots .slick-active button {
  background: #1e1e1e;
}
.divNewIn .slick-dots li button:before {
  display: none;
}
.divNewIn .slick-slide {
  width: 20% !important;
}
.divNewIn .slick-slide > div {
  margin: 0 5px;
}
.divNewIn .slick-list {
  margin: 0 -10px;
}

table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}

.inner-menu li:first-child {
  margin-top: 14px !important;
  text-transform: capitalize !important;
}

.oneofonebg {
  background-image: url("https://images.macmerise.com/macmerise2.0/icons/bg-oneofone.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  height: auto;
}
.blob {
  --blur: 30;
  --speed: 13;
  background: radial-gradient(
        62.61% 62.61% at 95.23% 6.02%,
        rgb(255, 37, 37) 0%,
        rgba(255, 37, 37, 0.42) 54.71%,
        rgba(90, 35, 248, 0) 100%
      )
      repeat scroll 0% 0% / auto padding-box border-box,
    linear-gradient(
        72.48deg,
        rgb(255, 232, 57) 2.61%,
        rgba(106, 103, 227, 0) 56.18%
      )
      repeat scroll 0% 0% / auto padding-box border-box,
    radial-gradient(
        45.23% 45.23% at 35.11% -11.02%,
        rgb(37, 255, 59) 0%,
        rgba(121, 54, 174, 0) 100%
      )
      repeat scroll 0% 0% / auto padding-box border-box,
    radial-gradient(
        94.51% 124.88% at 94.32% 94.43%,
        rgb(37, 242, 255) 0%,
        rgb(255, 232, 37) 32.29%,
        rgb(255, 232, 37) 64.06%,
        rgb(37, 242, 255) 100%
      )
      repeat scroll 0% 0% / auto padding-box border-box,
    rgba(0, 0, 0, 0)
      linear-gradient(
        313.04deg,
        rgb(37, 242, 255) 0.93%,
        rgb(255, 255, 255) 125.68%
      )
      repeat scroll 0% 0% / auto padding-box border-box;
  width: 440px;
  height: 440px;
  border-radius: 15rem;
  background-blend-mode: normal, normal, normal, normal, normal, normal;
  filter: blur(var(--blur, 20px));
  animation: rotate var(--speed) linear infinite;
  margin-bottom: 20px;
}

.animate-spin-slow {
  animation: spin 13s linear infinite; /* Adjust the animation duration (3s in this example) */
}
.animate-spin-fast {
  animation: spin 1s linear infinite; /* Adjust the animation duration (3s in this example) */
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.common-slider .swiper {
  padding-bottom: 0;
  bottom: 0;
}
.common-slider .swiper-wrapper {
  margin-bottom: 30px;
}
.common-slider .swiper-pagination-bullet-active {
  background: #fe5c46;
}
.common-slider .swiper-pagination-bullet {
  width: 40px;
  height: 2px;
  border-radius: inherit;
  margin: 0 !important;
}

.licensed-store .swiper-pagination {
  display: none;
}
.licensed-store .swiper-wrapper {
  margin-bottom: 0 !important;
}

.login-bg {
  background-image: url(https://images.macmerise.com/macmerise2.0/images/MacLogin_m.png);
  height: 230px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.react-tel-input .flag-dropdown {
  background: white !important;
  border: 1px solid #ccc;
}
.react-tel-input .selected-flag {
  width: 45px !important;
  padding: 0 8px 0 8px !important;
}

.max-h-0 {
  max-height: 0;
}

.max-h-96 {
  max-height: 24rem; /* Set the maximum height you want when the content is visible */
}

.ul-one li {
  opacity: 0;
  animation: fadeInDown 0.5s forwards;
}

.ul-two li {
  opacity: 0;
  animation: fadeInDown 0.5s forwards;
}

/* Delay each list item */

.ul-one li:nth-child(1) {
  animation-delay: 0.1s;
}
.ul-one li:nth-child(2) {
  animation-delay: 0.2s;
}
.ul-one li:nth-child(3) {
  animation-delay: 0.3s;
}
.ul-one li:nth-child(4) {
  animation-delay: 0.4s;
}
.ul-one li:nth-child(5) {
  animation-delay: 0.5s;
}

.ul-two li:nth-child(1),
li:nth-child(2) {
  animation-delay: 0.5s;
}
.ul-two li:nth-child(3),
li:nth-child(4),
li:nth-child(5),
li:nth-child(6),
li:nth-child(7) {
  animation-delay: 0.7s;
}
.ul-two li:nth-child(8),
li:nth-child(9),
li:nth-child(10) {
  animation-delay: 0.8s;
}
.ul-two li:nth-child(11),
li:nth-child(12),
li:nth-child(13),
li:nth-child(14),
li:nth-child(15),
li:nth-child(16) {
  animation-delay: 0.9s;
}
.ul-two li:nth-child(17),
li:nth-child(18),
li:nth-child(19),
li:nth-child(20),
li:nth-child(21),
li:nth-child(22) {
  animation-delay: 1s;
}
.ul-two li:nth-child(23),
li:nth-child(24),
li:nth-child(25),
li:nth-child(26),
li:nth-child(27) {
  animation-delay: 1.1s;
}

.label-fadeInDown {
  opacity: 1;
  animation-name: fadeInDown;
  animation-duration: 0.5s;
  animation-delay: 0.1s;
}
.label-fadeInUp {
  opacity: 1;
  animation-name: fadeInUp;
  animation-duration: 0.5s;
  animation-delay: 0.1s;
}

.ul-three-fadeInDown li {
  opacity: 0;
  animation: fadeInDown 0.5s forwards;
}

.ul-three-fadeInUp li {
  opacity: 0;
  animation: fadeInUp 0.5s forwards;
}

.ul-three li:nth-child(1) {
  animation-delay: 0.2s;
}
.ul-three li:nth-child(2) {
  animation-delay: 0.3s;
}
.ul-three li:nth-child(3) {
  animation-delay: 0.4s;
}
.ul-three li:nth-child(4) {
  animation-delay: 0.5s;
}
.ul-three li:nth-child(5) {
  animation-delay: 0.6s;
}

.wrap-one-fadeInDown {
  opacity: 0;
  animation: fadeInDown 0.5s forwards;
}

.wrap-one-fadeInUp {
  opacity: 0;
  animation: fadeInUp 0.5s forwards;
}

.wrap-one p {
  animation-delay: 0.7s;
}

.productCarousel.carousel-root .carousel.carousel-slider .slider-wrapper {
  border-radius: 0 !important;
}
/* .carousel .slide{min-width: 90% !important; margin-right: 10px !important;} */

.detail-carousel .control-dots .dot {
  background: #dbdbdb !important;
  width: 40px !important;
  height: 2px !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  opacity: 1 !important;
}
.detail-carousel .carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  background: #fe5c46 !important;
  width: 40px !important;
  height: 2px !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  opacity: 1 !important;
}

.button-top-shadow {
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1),
    1px -4px 2px -2px rgb(0 0 0 / 0.1);
}

.accordion-body .accordion-body-inner:last-child {
  margin-bottom: 0 !important;
}

.megamenu::-webkit-scrollbar {
  width: 0 !important;
}
.megamenu {
  overflow: -moz-scrollbars-none;
}
.megamenu {
  -ms-overflow-style: none;
}

.related-slider .swiper-slide,
swiper-slide {
  min-width: 118px;
  margin-top: 15px;
}

.witharrow .swiper-container {
  position: relative;
}

.witharrow .swiper-button-prev,
.witharrow .swiper-button-next {
  position: absolute;
  top: 40%;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 10;
}

.witharrow .swiper-button-prev {
  left: 10px;
  /* content: "\f104";
    font-family: 'fontAwesome';
    color: #000;
    background-color: #ccc; */
  background: url("https://images.macmerise.com/macmerise2.0/icons/left_arr.jpg")
    center/cover;
}

.witharrow .swiper-button-next {
  right: 10px;
  /* content: "\f105";
    font-family: 'fontAwesome';
    color: #000;
    background-color: #ccc; */
  background: url("https://images.macmerise.com/macmerise2.0/icons/right_arr.jpg")
    center/cover;
}

.checkout-form input[type="text"],
input[type="email"],
input[type="tel"]::placeholder {
  font-family: Graphik;
}

.filter-accordion ul {
  max-height: 200px;
  overflow-y: scroll; /* Add the ability to scroll */
}

.filter-accordion ul::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.filter-accordion ul::-webkit-scrollbar-track {
  background: #d9d9d9;
}

/* Handle */
.filter-accordion ul::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

/* Handle on hover */
.filter-accordion ul::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.footer-content h2 {
  font-family: "Graphik";
  /* color: #fff; */
  text-transform: uppercase;
  font-size: 18px;
}
.footer-content h3 {
  font-family: "Graphik";
  /* color: #fff; */
  text-transform: uppercase;
  font-size: 16px;
  margin-top: 40px;
}
.footer-content p {
  font-family: "Graphik";
  /* color: #fff; */
  font-size: 14px;
  margin-top: 20px;
}
/* .footer-content div{margin-top: 40px;} */
.footer-content .que {
  font-family: "Graphik";
  /* color: #fff; */
  font-size: 16px;
  margin-top: 40px;
}
.footer-content .ans {
  font-family: "Graphik";
  /* color: #fff; */
  font-size: 14px;
  margin-top: 20px;
}
.footer-content .faq {
  margin-top: 100px;
}

@media screen and (min-width: 1550px) {
  .login-wrapper {
    width: 65% !important;
    max-width: 1048px !important;
  }
}

.ais-SearchBox {
  margin-bottom: 1em;
}

.ais-Highlight-highlighted,
.ais-Snippet-highlighted {
  color: hsl(var(--primary)) !important;
  background-color: rgb(253 235 239) !important;
}

.ais-InstantSearch {
  max-width: 960px;
  width: 100%;
  display: block;
  overflow: hidden;
  margin: 0 auto;
}

.ais-Hits-list .ais-Hits-item {
  margin-left: 0;
  width: 100%;
  padding: 0;
}

.ais-SearchBox-form::before {
  background: rgb(221 10 10 / 0%)
    url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%23ccc%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Ccircle%20cx%3D%2211%22%20cy%3D%2211%22%20r%3D%228%22%2F%3E%3Cpath%20d%3D%22m21%2021-4.35-4.35%22%2F%3E%3C%2Fsvg%3E")
    repeat scroll 0% 0% !important;
}

.ais-Hits-item img {
  margin-right: 1em;
}

.ais-InstantSearch .ais-Hits-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  margin-left: 0;
  margin-top: 0;
}

/* AI THINGS */
/* hardcoded canvas styles */
canvas {
  @apply h-auto w-full mx-auto pointer-events-none;
}

/* .tile.active-tile::before {
  content: '';
  position: absolute;
  top: -35px;
  right: -35px;
  height: 50%;
  width: 50%;
  transform: rotate(135deg);
} */

:root {
  --gap: 1rem;
  --tile-flex: 1;
  --tile-aspect-ratio: 3/4; /* -- Change Aspect Ratio Only -- */
  --tile-background-size: 200% 200%;
  --tile-border-radius: 8px;
  --tile-background-repeat: no-repeat;
  --tile-background-image: url("https://cdn.discordapp.com/attachments/1155149481537445982/1252922575479246960/1of1_mcd_Blue_Sunny_Bright_Beach_8f49b8b3-666f-4ef2-810a-47f3d70e2730.png?ex=6673fa94&is=6672a914&hm=e765dff3a4d6e00b692487aa102e7780e51323583c0310b718f4143f70981338");

  --background-position-top-left: 0% 0%;
  --background-position-top-right: 100% 0%;
  --background-position-bottom-left: 0% 100%;
  --background-position-bottom-right: 100% 100%;
}

.image-grid {
  display: flex;
  width: 100%;
  position: relative;
  gap: var(--gap);
}

.tile {
  background-image: var(--tile-background-image);
  flex: var(--tile-flex);
  height: auto;
  aspect-ratio: var(--tile-aspect-ratio); /* -- This is important --*/
  background-size: var(--tile-background-size);
  border-radius: var(--tile-border-radius);
  background-repeat: var(--tile-background-repeat);
}

.tile.top-left {
  background-position: var(--background-position-top-left);
}

.tile.top-right {
  background-position: var(--background-position-top-right);
}

.tile.bottom-left {
  background-position: var(--background-position-bottom-left);
}

.tile.bottom-right {
  background-position: var(--background-position-bottom-right);
}

/* AI THINGS END */

/* Tracking Progress Order Status */

:root {
  --current-progress: 0%;
  --active-dot: #40df75;
  --inactive-dot: #b3b3b3;
  --active-line: #40df75;
  --inactive-line: #b3b3b3;
}

.horizontal.timeline {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.horizontal.timeline:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 0.5rem;
  background-color: var(--inactive-line);
}
.horizontal.timeline .line {
  display: block;
  position: absolute;
  width: var(--current-progress);
  height: 0.5rem;
  background-color: var(--active-line);
}
.horizontal.timeline .steps {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.horizontal.timeline .steps .step {
  display: block;
  position: relative;
  bottom: calc(100% + 1em);
  padding: 1.45em;
  margin: 0 2em;
  box-sizing: content-box;
  background-color: var(--active-dot);
  color: #101010;
  border: 0.25em solid white;
  border-radius: 50%;
  z-index: 500;
}

.horizontal.timeline .steps .step span {
  position: absolute;
  top: calc(100% + 1em);
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  color: #101010;
  font-weight: 400;
  opacity: 1;
}

.horizontal.timeline .steps .step.current ~ .step span {
  color: var(--inactive-dot);
}

.horizontal.timeline .steps .step.current span {
  /* color: var(--active-dot); */
  font-weight: 400;
}

.horizontal.timeline .steps .step.current::before,
.horizontal.timeline .steps .step::before {
  content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%221.5em%22%20height%3D%221.5em%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22white%22%20stroke-width%3D%223%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20class%3D%22lucide%20lucide-check%22%3E%3Cpath%20d%3D%22M20%206%209%2017l-5-5%22%2F%3E%3C%2Fsvg%3E");
}

.horizontal.timeline .steps .step::before {
  position: absolute;
  white-space: pre;
  display: inline;
  top: 50%;
  left: 50%;
  color: white;
  transform: translate(-50%, -35%);
  z-index: 999;
}

.horizontal.timeline .steps .step.current::before {
  position: absolute;
  white-space: pre;
  display: inline;
  top: 50%;
  left: 50%;
  color: white;
  transform: translate(-50%, -35%);
  z-index: 999;
}

.horizontal.timeline .steps .step.current ~ .step {
  background-color: var(--inactive-dot);
  font-weight: 400;
}

.horizontal.timeline .steps .step.current ~ .step::before {
  /* content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%221em%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22white%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20class%3D%22lucide%20lucide-hourglass%22%3E%3Cpath%20d%3D%22M5%2022h14M5%202h14m-2%2020v-4.172a2%202%200%200%200-.586-1.414L12%2012l-4.414%204.414A2%202%200%200%200%207%2017.828V22M7%202v4.172a2%202%200%200%200%20.586%201.414L12%2012l4.414-4.414A2%202%200%200%200%2017%206.172V2%22%2F%3E%3C%2Fsvg%3E"); */
  content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%221.5em%22%20height%3D%221.5em%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22white%22%20stroke-width%3D%223%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20class%3D%22lucide%20lucide-check%22%3E%3Cpath%20d%3D%22M20%206%209%2017l-5-5%22%2F%3E%3C%2Fsvg%3E");
  position: absolute;
  white-space: pre;
  display: inline;
  top: 50%;
  left: 50%;
  color: white;
  transform: translate(-50%, -35%);
  z-index: 999;
}

.horizontal.timeline .steps .step:first-child {
  margin-left: 0;
}
.horizontal.timeline .steps .step:last-child {
  margin-right: 0;
  color: #40df75;
}

.horizontal.timeline .steps .step.current span {
  opacity: 0.8;
}

@keyframes animation-timeline-current {
  from {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }
  to {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}

/* Tracking Progress Order Status END */

/* HTML: <div class="loader"></div> */
.ai-loader {
  width: 85px;
  aspect-ratio: 1;
  --c: no-repeat linear-gradient(#000 0 0);
  background: var(--c), var(--c), var(--c);
  animation: l16-1 1s infinite, l16-2 1s infinite;
}
@keyframes l16-1 {
  0%,
  100% {
    background-size: 20% 100%;
  }
  33%,
  66% {
    background-size: 20% 40%;
  }
}
@keyframes l16-2 {
  0%,
  33% {
    background-position: 0 0, 50% 100%, 100% 0;
  }
  66%,
  100% {
    background-position: 0 100%, 50% 0, 100% 100%;
  }
}

.ais-SearchBox {
  margin-bottom: 1em;
}

.ais-Highlight-highlighted,
.ais-Snippet-highlighted {
  color: hsl(var(--primary)) !important;
  background-color: rgba(106, 88, 244, 0.773) !important;
}

.ais-InstantSearch {
  max-width: 960px;
  width: 100%;
  display: block;
  overflow: hidden;
  margin: 0 auto;
}

.ais-Hits-list .ais-Hits-item {
  margin-left: 0;
  width: 100%;
  padding: 0;
}

.ais-SearchBox-form::before {
  background: rgb(221 10 10 / 0%)
    url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%23ccc%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Ccircle%20cx%3D%2211%22%20cy%3D%2211%22%20r%3D%228%22%2F%3E%3Cpath%20d%3D%22m21%2021-4.35-4.35%22%2F%3E%3C%2Fsvg%3E")
    repeat scroll 0% 0% !important;
}

.ais-Hits-item img {
  margin-right: 1em;
}

.ais-InstantSearch .ais-Hits-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  margin-left: 0;
  margin-top: 0;
}

.ais-Hits-item,
.ais-FrequentlyBoughtTogether-item,
.ais-LookingSimilar-item,
.ais-RelatedProducts-item,
.ais-TrendingItems-item {
  align-items: center;
  color: #101010 !important;
  background: #ffffff !important;
  box-shadow: 0 0 0 1px rgb(36 37 41 / 8%), 0 1px 3px 0 #604bcc20 !important;
  display: flex;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  padding: 1.5rem;
}

#allproducts a[href="/shopbyproducts"].xs\:text-\[16px\] {
  @apply !font-sans font-semibold;
}

/* Countdown */

.example {
  font-family: "JustSans", sans-serif;
  width: 550px;
  /* height: 378px; */
  margin: auto;
  padding: 20px;
  box-sizing: border-box;
}

.example .flipdown {
  margin: auto;
}
@media (max-width: 550px) {
  .example {
    width: 100%;
    /* height: 362px; */
  }
}

#flipdown {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-left: unset !important; */
}
#flipdown .rotor-group:first-child,
#flipdown .rotor-group:nth-child(2),
#flipdown .rotor-group:nth-child(3) {
  display: none !important;
}

.skeleton {
  @apply animate-pulse bg-gray-200 rounded;
}

.cr.swiper-button-prev {
  height: 30px !important;
  width: 30px !important;
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2230%22%20height%3D%2230%22%20viewBox%3D%220%200%2030%2030%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M22%2014.001H10.14l3.63-4.36a1.001%201.001%200%200%200-1.54-1.28l-5%206a1%201%200%200%200-.09.15c0%20.05%200%20.08-.07.13a1%201%200%200%200-.07.36%201%201%200%200%200%20.07.36c0%20.05%200%20.08.07.13q.039.079.09.15l5%206a1%201%200%200%200%201.54-1.28l-3.63-4.36H22a1%201%200%200%200%200-2%22%20fill%3D%22%23101010%22%2F%3E%3C%2Fsvg%3E") !important;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.cr.swiper-button-next {
  height: 30px !important;
  width: 30px !important;
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2230%22%20height%3D%2230%22%20viewBox%3D%220%200%2030%2030%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M8%2015.9997L19.86%2015.9997L16.23%2020.3597C16.0603%2020.5639%2015.9786%2020.8272%2016.003%2021.0916C16.0274%2021.3561%2016.1558%2021.6%2016.36%2021.7697C16.5642%2021.9395%2016.8275%2022.0211%2017.0919%2021.9967C17.3563%2021.9724%2017.6003%2021.8439%2017.77%2021.6397L22.77%2015.6397C22.8036%2015.592%2022.8337%2015.5419%2022.86%2015.4897C22.86%2015.4397%2022.86%2015.4097%2022.93%2015.3597C22.9753%2015.2451%2022.9991%2015.123%2023%2014.9997C22.9991%2014.8764%2022.9753%2014.7544%2022.93%2014.6397C22.93%2014.5897%2022.93%2014.5597%2022.86%2014.5097C22.8337%2014.4576%2022.8036%2014.4074%2022.77%2014.3597L17.77%208.35972C17.676%208.24684%2017.5582%208.15606%2017.4252%208.09384C17.2921%208.03162%2017.1469%207.99949%2017%207.99972C16.7663%207.99927%2016.5399%208.08064%2016.36%208.22972C16.2587%208.31367%2016.175%208.41677%2016.1137%208.53312C16.0523%208.64947%2016.0145%208.77677%2016.0025%208.90775C15.9904%209.03873%2016.0043%209.1708%2016.0433%209.2964C16.0824%209.42201%2016.1458%209.53867%2016.23%209.63972L19.86%2013.9997L8%2013.9997C7.73478%2013.9997%207.48043%2014.1051%207.29289%2014.2926C7.10536%2014.4802%207%2014.7345%207%2014.9997C7%2015.2649%207.10536%2015.5193%207.29289%2015.7068C7.48043%2015.8944%207.73478%2015.9997%208%2015.9997Z%22%20fill%3D%22%23101010%22%2F%3E%3C%2Fsvg%3E") !important;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.cr.swiper-button-next::after {
  display: none;
}

.cr.swiper-button-prev::after {
  display: none;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}