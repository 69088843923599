@font-face {
  font-family: "Graphik";
  src: url("../public/fonts/graphik/Graphik-Bold.eot");
  src: url("../public/fonts/graphik/Graphik-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/graphik/Graphik-Bold.woff2") format("woff2"),
    url("../public/fonts/graphik/Graphik-Bold.woff") format("woff"),
    url("../public/fonts/graphik/Graphik-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("../public/fonts/graphik/Graphik-Medium.eot");
  src: url("../public/fonts/graphik/Graphik-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/graphik/Graphik-Medium.woff2") format("woff2"),
    url("../public/fonts/graphik/Graphik-Medium.woff") format("woff"),
    url("../public/fonts/graphik/Graphik-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("../public/fonts/graphik/Graphik-Regular.eot");
  src: url("../public/fonts/graphik/Graphik-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/graphik/Graphik-Regular.woff2") format("woff2"),
    url("../public/fonts/graphik/Graphik-Regular.woff") format("woff"),
    url("../public/fonts/graphik/Graphik-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("../public/fonts/graphik/Graphik-Semibold.eot");
  src: url("../public/fonts/graphik/Graphik-Semibold.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/graphik/Graphik-Semibold.woff2") format("woff2"),
    url("../public/fonts/graphik/Graphik-Semibold.woff") format("woff"),
    url("../public/fonts/graphik/Graphik-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

/* Just sans */
@font-face {
  font-family: "JustSans";
  src: url("../public/fonts/just-sans/JUST-Sans-Bold.woff2");
  src: url("../public/fonts/just-sans/JUST-Sans-Bold.woff2") format("woff2"),
    url("../public/fonts/just-sans/JUST-Sans-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
/* Just sans */
@font-face {
  font-family: "JustSans";
  src: url("../public/fonts/just-sans/JUST-Sans-SemiBold.woff2");
  src: url("../public/fonts/just-sans/JUST-Sans-SemiBold.woff2") format("woff2"),
    url("../public/fonts/just-sans/JUST-Sans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
/* Just sans */
@font-face {
  font-family: "JustSans";
  src: url("../public/fonts/just-sans/JUST-Sans-Regular.woff2");
  src: url("../public/fonts/just-sans/JUST-Sans-Regular.woff2") format("woff2"),
    url("../public/fonts/just-sans/JUST-Sans-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

/* OLD */
/* @font-face {
  font-family: "Avenir-Black-03";
  src: url("https://images.macmerise.com/assets/font/Avenir-Black-03.ttf")
    format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Avenir-Heavy-05";
  src: url("https://images.macmerise.com/assets/font/Avenir-Heavy-05.ttf")
    format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Avenir-Medium-09";
  src: url("https://images.macmerise.com/assets/font/Avenir-Medium-09.ttf")
    format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Avenir-Book-01";
  src: url("https://images.macmerise.com/assets/font/Avenir-Book-01.ttf")
    format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Avenir-Light-07";
  src: url("https://images.macmerise.com/assets/font/Avenir-Light-07.ttf")
    format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Avenir-Roman-12";
  src: url("https://images.macmerise.com/assets/font/Avenir-Roman-12.ttf")
    format("truetype");
  font-display: swap;
} */

.avenir-black {
  font-family: JustSans;
  font-weight: 600;
}
.avenir-heavy {
  font-family: JustSans;
}
.avenir-medium {
  font-family: Graphik;
}
.avenir-book {
  font-family: Graphik;
}
.avenir-light {
  font-family: Graphik;
}
.avenir-roman {
  font-family: Graphik;
}
